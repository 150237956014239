import React from 'react';
import { useSelector } from 'react-redux';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Image from 'Components/common/image';
import LH2 from 'Components/common/localizedTags/localizedHeading2';
import LSpan from 'Components/common/localizedTags/localizedSpan';

import { getCurrentLanguageCode } from 'Utils/stateUtils';

import { APP_VENDOR_ICONS, IPHONE_MOCKUP_IMAGE_URL } from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const DownloadAppContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-width: 70rem;
	max-width: 75rem;
	height: ${props => ((props as any).isMid ? '21.625rem' : '24.125rem')};
	margin: 0 auto;
	background: ${props =>
		(props as any).isMid ? colors.GREY.FLOATING : 'colors.WHITE'};
	border-radius: ${props => ((props as any).isMid ? '0.75rem' : 0)};
	overflow: hidden;

	.phone-wrapper {
		img {
			background: ${props =>
				(props as any).isMid ? colors.GREY.FLOATING : colors.WHITE};
			object-fit: cover;
		}
	}

	.on-visible picture > img {
		background: none;
	}
`;

const AppDetailsSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: ${props => ((props as any).isMid ? '0' : '0.875rem')};
	padding: ${props => ((props as any).isMid ? '4rem 4.875rem 5.25rem' : 0)};

	.title {
		color: ${colors.GREY_DS.G2};
		cursor: default;
		margin-top: 0.9375rem;
		${getFontDetailsByLabel(TYPE_LABELS.DISPLAY_SMALL)};
	}

	.subtitle {
		margin-top: 1rem;
		color: ${colors.GREY_DS.G2};
		width: 27rem;
		cursor: default;
		${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_LARGE)};
	}

	.download-links {
		display: flex;
		margin-top: 3rem;

		a img {
			height: 2.5rem;
		}
	}

	.play-store {
		margin-left: 1rem;
	}
`;

const DownloadAppSection = ({ isMid }: { isMid?: boolean }) => {
	const languageCode = useSelector(state => getCurrentLanguageCode(state));

	return (
		// @ts-expect-error TS(2769): No overload matches this call.
		<DownloadAppContainer isMid={isMid}>
			{/* @ts-expect-error TS(2769): No overload matches this call. */}
			<AppDetailsSection isMid={isMid}>
				<LH2 className='title'>{strings.DOWNLOAD_APPS.TITLE}</LH2>
				<LSpan className='subtitle'>
					{strings.DOWNLOAD_APPS.SUB_HEADING}
				</LSpan>
				<div className='download-links'>
					<a
						rel='noopener noreferrer'
						target='_blank'
						href={`https://itunes.apple.com/${languageCode.toLowerCase()}/app/tourlandish/id899327000?ls=1&mt=8`}
						onMouseDown={e => {
							e.stopPropagation();
						}}
					>
						<Image
							alt='Download iPhone app'
							src={APP_VENDOR_ICONS.APPSTORE}
							height={64}
							width={120}
						/>
					</a>
					<a
						rel='noopener noreferrer'
						target='_blank'
						href={`https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=${languageCode.toLowerCase()}`}
						className='play-store'
						onMouseDown={e => {
							e.stopPropagation();
						}}
					>
						<Image
							alt='Download Android app'
							src={APP_VENDOR_ICONS.PLAYSTORE}
							width={136}
							height={64}
						/>
					</a>
				</div>
			</AppDetailsSection>
			<div className='phone-wrapper'>
				<div className='iphone-wrapper'>
					<Image
						alt='iphone'
						className='image'
						src={IPHONE_MOCKUP_IMAGE_URL}
						height={400}
						width={640}
					/>
				</div>
			</div>
		</DownloadAppContainer>
	);
};

export default DownloadAppSection;
